import { action } from 'reduxHelpers';
import { USER, FETCHSURVEYRESULT, SUBMITSURVEY, FETCHSUPPLIERS, FETCHRESULTFORINTERNAL, DOWNLOADFILE } from './actionTypes';

const loadUserDetails = userId => action(USER.REQUEST, { userId });
const fetchSurveyResult = suvc => action(FETCHSURVEYRESULT.REQUEST, { suvc });
const fetchSuppliers = () => action(FETCHSUPPLIERS.REQUEST);
const submitSurvey = (params) => action(SUBMITSURVEY.REQUEST, { ...params });
const fetchResultForInternal = suvc => action(FETCHRESULTFORINTERNAL.REQUEST, { suvc });
const downloadFile = (documentId, fileName) => action(DOWNLOADFILE.REQUEST, { documentId, fileName });

export { loadUserDetails, fetchSurveyResult, submitSurvey, fetchSuppliers, fetchResultForInternal, downloadFile };
