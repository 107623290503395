import { notification, Modal } from 'antd';
import React from 'react';

notification.config({
  placement: 'bottomRight'
});

const openNotification = payload => {
  const { message, description, className } = payload;
  const key = `open${Date.now()}`;

  notification[className]({
    message,
    description,
    key,
    className
  });
};

const openSubmitSuccessInfo = payload => {
  Modal.info({
    content: (
      <div>
        <p>Thank you for completing the survey.</p>
        <p>If your climate commitments change, please return to this tile and</p>
        <p>resubmit your response.</p>
      </div>
    ),
    onOk() {
      payload.history.push('/suite/dashboard');
    },
    width: '520px',
    okText: 'Close'
  });
};

export { openNotification, openSubmitSuccessInfo };
