import React from 'react';
import PropTypes from 'prop-types';
// import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

const Summary = ({ t }) => {
  return (
    <div className="detail-wrapper">
      <div className="title">{t('survey.title')}</div>
      <p>{t('survey.section1')}</p>
      <p>
        {t('survey.section2-1')}
        <b>{t('survey.section2-2')}</b>
        {t('survey.section2-3')}
        <a
          className="hyper-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.sysco.com/dam/jcr:4f5dc6a4-eb24-461a-a5f8-a155a11ec344/CSR - Climate Goals Flyer 2761399 Corp042022_FINAL (1).pdf"
        >
          {t('survey.section2-4')}
        </a>
        {t('survey.section2-5')}
        <b>{t('survey.section2-6')}</b>
      </p>
      <p>
        {t('survey.section3-1')}
        <b>{t('survey.section3-2')}</b>
      </p>
      <p>
        <b>Links:</b>
        <br></br>
        {/* <a 
          className="hyper-link"
          target="_blank"
          rel="noopener noreferrer"
          href="/suite/sustainability"
        >
          Sustainability Information Hub
        </a> */}
        <Link key="toSustainability" to="/suite/sustainability">
          Sustainability Information Hub
        </Link>
        <br></br>
        <a
          className="hyper-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://sciencebasedtargets.org/faqs#what-are-the-emissions-scopes-which-scopes-do-targets-have-to-cover"
        >
          Science-Based Targets Website
        </a>
      </p>
    </div>
  );
};

Summary.propTypes = {
  t: PropTypes.func
};

export default Summary;
