import axios from 'axios';
import qs from 'qs';
// import { getOtmmBaseUrl } from '../util/util';
import { getIdToken } from '../Authenticator';

/*const getUploadHeaders = async () => {
  const token = (await getIdToken()).getJwtToken();
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'multipart/form-data'
  };
};*/

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BFF,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(async (request) => {
  const token = (await getIdToken()).getJwtToken();
  request.headers.Authorization = 'Bearer ' + token;
  return request;
});

axiosInstance.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      // your failure logic
    } else if (response.status === 500) {
      return 'Please try again later';
    }
    return response;
  },
  function (error) {
    return error;
  }
);

const postRequest = async (url, data) => {
  return axiosInstance.post(`${url}`, data, {});
};

const getRequest = async (url, params = {}) => {
  return axiosInstance.get(`${url}`, {
    params,
    paramsSerializer: () => qs.stringify(params)
  });
};

const putRequest = async (url, data) => {
  return axiosInstance.put(`${url}`, data, {});
};

const patchRequest = async (url, data) => {
  return axiosInstance.patch(`${url}`, data, {});
};

const deleteRequest = async url => {
  return axiosInstance.delete(`${url}`, {});
};

/*const fileUploadRequest = async (url, data) => {
  let baseDomain = getOtmmBaseUrl();
  return axios.post(`${baseDomain}${url}`, data, {
    headers: await getUploadHeaders()
  }).then(result => {
    return result;
  }).catch(error => {
    return error.response;
  });
};*/

const axiosInstanceDocument = axios.create({
  baseURL: process.env.REACT_APP_DOCUMENT,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});

axiosInstanceDocument.interceptors.request.use(async (request) => {
  const token = (await getIdToken()).getJwtToken();
  request.headers.Authorization = 'Bearer ' + token;
  return request;
});

axiosInstanceDocument.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      // your failure logic
    } else if (response.status === 500) {
      return 'Please try again later';
    }
    return response;
  },
  function (error) {
    return error;
  }
);

const fileUploadRequest = async (url, data) => {
  return axiosInstanceDocument.post(`${url}`, data, {});
};

const fileDownloadRequest = async (url, params = {}) => {
  return axiosInstanceDocument.get(`${url}`, {
    params,
    paramsSerializer: () => qs.stringify(params),
    responseType: "blob"
  });
};

export {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest, 
  patchRequest,
  fileUploadRequest,
  fileDownloadRequest
};
