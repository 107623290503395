import React, { useEffect } from 'react';
import * as actions from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import AppLoader from 'components/Templates/AppLoader';
import SurveyView from 'components/SurveyView';
import { checkUserHasSearchPermissions } from '../util/util';

const Home = ({ history }) => {
  const dispatch = useDispatch();
  const suvc = useSelector(state => _.get(state.user, 'data.suvc', null));
  const surveyFetching = useSelector(state => _.get(state.survey, 'fetchingSurveyResult', false));
  const userFetching = useSelector(state => _.get(state.user, 'fetching', false));
  const vendorsFetching = useSelector(state => _.get(state.survey, 'fetchingVendors', false));
  const permissions = useSelector(state => _.get(state.user, 'data.permissions', []));
  const hasSearchPermissions = checkUserHasSearchPermissions(permissions);

  useEffect(() => {
    if (suvc && !hasSearchPermissions) {
      dispatch(actions.fetchSurveyResult(suvc));
    }
    if (hasSearchPermissions) {
      dispatch(actions.fetchSuppliers());
    }
  }, [dispatch, suvc, hasSearchPermissions]);

  let comp = null;
  if (!surveyFetching && !userFetching && !vendorsFetching) {
    comp = <SurveyView history={history} />;
  } else {
    comp = <AppLoader show />;
  }
  return comp;
};

export default Home;
