import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import store from 'store';
import { Provider } from 'react-redux';
import MainApp from './MainApp';
import i18n from './_i18n';
import styles from './styles/main.scss';

function App({ language, Auth }) {
  i18n.changeLanguage(language);
  const {
    user: { username }
  } = Auth;
  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  return (
    <Provider store={store}>
      <MainApp userId={username} />
    </Provider>
  );
}

App.propTypes = {
  language: PropTypes.string.isRequired
};

export default App;
