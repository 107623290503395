import { put } from 'redux-saga/effects';
import { fileDownloadRequest, fileUploadRequest, getRequest, putRequest } from '_http';
import { FETCHSURVEYRESULT, SUBMITSURVEY, FETCHSUPPLIERS, FETCHRESULTFORINTERNAL } from 'actions/actionTypes';
import { action } from 'reduxHelpers';
import { getFieldDisabledStatusByTwoParams, getFileNameWithoutExtension, getUniqueId } from '../../util/util';

function* fetchSurveyResultAsync({ suvc }) {
  try {
    const { data } = yield getRequest(`/question/suvc/${suvc}`);
    if (data.status === 200) {
      yield put({ type: FETCHSURVEYRESULT.SUCCESS, payload: data.data });
    } else {
      yield put({ type: FETCHSURVEYRESULT.FAILURE, payload: data.data.message });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: data.data.description,
          className: 'error',
          message: data.data.message
        })
      );
    }
  } catch (error) {
    console.log(error)
    yield put({ type: FETCHSURVEYRESULT.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'SURVEY LOADING ERROR'
      })
    );
  }
}

function* submitSurveyAsync({ suvc, contactId, answerList, fileList, stepParams, subSuvcs, folderId, expiryDate, oldDocumentId, oldFileName, history }) {
  try {
    if (fileList.length && fileList[0].originFileObj) {
      const bodyFormData = new FormData();
      const file = fileList[0];
      bodyFormData.append('folderId', folderId);
      bodyFormData.append('fileName', `${getFileNameWithoutExtension(file.name)}_${getUniqueId()}`);
      bodyFormData.append('document', file.originFileObj);
      bodyFormData.append('expiryDate', expiryDate);
      bodyFormData.append('suvc', suvc);
      bodyFormData.append('section', 'ESG');
      bodyFormData.append('sectionAlt', '');
      bodyFormData.append('certType', '');

      const uploadResponse = yield fileUploadRequest('/file/upload', bodyFormData);
      if (uploadResponse && uploadResponse.data && uploadResponse.data.results && uploadResponse.data.results.data &&
        uploadResponse.data.results.data.properties && uploadResponse.data.results.data.properties.id) {
        const documentId = uploadResponse.data.results.data.properties.id + '';
        const certification = { otmmId: documentId, title: file.name };
        localStorage.setItem(`${suvc}_folderId`, uploadResponse.data.results.data.properties.parent_id + '');
        const response = yield putRequest(`/question/submit`, {
          suvc,
          contactId,
          answerList,
          stepParams,
          certification,
          folderId: uploadResponse.data.results.data.properties.parent_id + ''
        });
        if (response.data.status === 200) {
          yield put({ type: SUBMITSURVEY.SUCCESS, payload: response.data });
          yield put(action('SHOW_SURVEY_SUBMIT_SUCCESS_INFO', { history }));
          // No need to wait and No need to process responses
          if (subSuvcs.length) putRequest(`/question/updateSubSupplier`, { subSuvcs, answerList, stepParams, certification });
        } else {
          yield put({ type: SUBMITSURVEY.FAILURE, payload: response.data.data.message });
          yield put(
            action('SHOW_NOTIFICATION', {
              description: response.data.data.description,
              className: 'error',
              message: response.data.data.message
            })
          );
        }
      } else {
        const errMsg = getFieldDisabledStatusByTwoParams('', 'something went wrong');//TODO uploadResponse.data.msg
        yield put({ type: SUBMITSURVEY.FAILURE, payload: errMsg });
        yield put(
          action('SHOW_NOTIFICATION', {
            description: errMsg,
            className: 'error',
            message: 'FILE UPLOAD FAILED'
          })
        );
      }
    } else {
      const certification = { otmmId: oldDocumentId, title: oldFileName };
      localStorage.setItem(`${suvc}_folderId`, folderId);
      const response = yield putRequest(`/question/submit`, {
        suvc,
        contactId,
        answerList,
        stepParams,
        certification,
        folderId
      });
      if (response.data.status === 200) {
        yield put({ type: SUBMITSURVEY.SUCCESS, payload: response.data });
        yield put(action('SHOW_SURVEY_SUBMIT_SUCCESS_INFO', { history }));
        // No need to wait and No need to process responses
        if (subSuvcs.length) putRequest(`/question/updateSubSupplier`, { subSuvcs, answerList, stepParams, certification });
      } else {
        yield put({ type: SUBMITSURVEY.FAILURE, payload: response.data.data.message });
        yield put(
          action('SHOW_NOTIFICATION', {
            description: response.data.data.description,
            className: 'error',
            message: response.data.data.message
          })
        );
      }
    }
  } catch (error) {
    yield put({ type: SUBMITSURVEY.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Internal Server Error',
        className: 'error',
        message: error.message
      })
    );
  }
}

function* fetchSuppliersAsync() {
  try {
    const { data } = yield getRequest(`/question/vendors`);
    yield put({ type: FETCHSUPPLIERS.SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: FETCHSUPPLIERS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'SUPPLIERS LOADING ERROR'
      })
    );
  }
}

function* fetchResultForInternalAsync({ suvc }) {
  try {
    const { data } = yield getRequest(`/question/suvc/${suvc}`);
    if (data.status === 200) {
      yield put({ type: FETCHRESULTFORINTERNAL.SUCCESS, payload: data.data });
    } else {
      yield put({ type: FETCHRESULTFORINTERNAL.FAILURE, payload: data.data.message });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: data.data.description,
          className: 'error',
          message: data.data.message
        })
      );
    }
  } catch (error) {
    yield put({ type: FETCHRESULTFORINTERNAL.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'SURVEY LOADING ERROR'
      })
    );
  }
}

function* downloadFileAsync({ documentId, fileName }) {
  try {
    const downloadResp = yield fileDownloadRequest(`/file/fetch/${documentId}`);
    // const filename = downloadResp?.headers['content-disposition']
    const contentType = downloadResp?.headers['content-type']
    const url = window.URL.createObjectURL(new Blob([downloadResp?.data], { type: contentType }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  } catch (error) {
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'DOWNLOAD FILE ERROR'
      })
    );
  }
}

export { fetchSurveyResultAsync, submitSurveyAsync, fetchSuppliersAsync, fetchResultForInternalAsync, downloadFileAsync };
