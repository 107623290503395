export const DASH_DATE_FORMAT = 'YYYY-MM-DD';
export const SLASH_DATE_FORMAT = 'MM/DD/YYYY';
export const COMMON_DATE_FORMAT = 'YYYYMMDD';

export const SSSTOSTEP = {
  'No': 'No',
  'SBTi aligned but not SBTi approved': 'SBTi aligned but not SBTi approved',
  'Committed': 'Committed',
  'Targets Set, 2°C': 'Targets Set, 2°C',
  'Targets Set, Well-below 2°C': 'Targets Set, Well-below 2°C',
  'Targets Set, 1.5°C': 'Targets Set, 1.5°C'
};
