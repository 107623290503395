const initialState = {
  user: {
    fetching: false,
    data: {
      contactId: null,
      email: null,
      name: null,
      permissions: [],
      roles: [],
      suvc: null,
      validTill: null,
      department: null,
      selectedVendor: null
    },
    error: null
  },
  survey: {
    fetchingSurveyResult: false,
    submitingSurvey: false,
    fetchingResultForInternal: false,
    data: {
      questions: [],
      issueDate: '',
      certificationInfo: {}
    },
    vendors: [],
    fetchingVendors: false,
    error: null
  }
};

export default initialState;
