import initialState from 'store/initialState';
import { FETCHSURVEYRESULT, SUBMITSURVEY, FETCHSUPPLIERS, FETCHRESULTFORINTERNAL } from 'actions/actionTypes';

const survey = (state = initialState.survey, action) => {
  switch (action.type) {
    case FETCHSURVEYRESULT.REQUEST:
      return { ...state, fetchingSurveyResult: true, error: null };
    case FETCHSURVEYRESULT.SUCCESS:
      return { ...state, fetchingSurveyResult: false, data: action.payload };
    case FETCHSURVEYRESULT.FAILURE:
      return { ...state, fetchingSurveyResult: false, data: [], error: action.payload };
    case SUBMITSURVEY.REQUEST:
      return { ...state, submitingSurvey: true, error: null };
    case SUBMITSURVEY.SUCCESS:
      return { ...state, submitingSurvey: false };
    case SUBMITSURVEY.FAILURE:
      return { ...state, submitingSurvey: false, error: action.error };
    case FETCHSUPPLIERS.REQUEST:
      return { ...state, fetchingVendors: true, error: null };
    case FETCHSUPPLIERS.SUCCESS:
      return {
        ...state,
        fetchingVendors: false,
        vendors: action.payload,
        data: {
          questions: [],
          issueDate: '',
          certificationInfo: {}
        }
      };
    case FETCHSUPPLIERS.FAILURE:
      return { ...state, fetchingVendors: false, vendors: [], error: action.payload };
    case FETCHRESULTFORINTERNAL.REQUEST:
      return { ...state, fetchingResultForInternal: true, error: null };
    case FETCHRESULTFORINTERNAL.SUCCESS:
      return { ...state, fetchingResultForInternal: false, data: action.payload };
    case FETCHRESULTFORINTERNAL.FAILURE:
      return { ...state, fetchingResultForInternal: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default survey;
