import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { USER } from 'actions/actionTypes';
import { action } from 'reduxHelpers';

const adPrefix = process.env.REACT_APP_AD_PREFIX;
const oktaPrefix = process.env.REACT_APP_OKTA_PREFIX;

const getUserId = username => {
  if (username.includes(adPrefix)) {
    return username.split('@')[0].replace(adPrefix, '');
  }
  const oktaEx = new RegExp(oktaPrefix, 'ig');
  return username.replace(oktaEx, '');
};

function* loadUserAsync({ userId }) {
  try {
    const response = yield getRequest(`/user/${getUserId(userId)}?app_name=SUSTAINABILITYSOWAPP`);
    yield put({ type: USER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: USER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'USER LOADING ERROR'
      })
    );
  }
}

export default loadUserAsync;
