import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Button, Select, Spin, Upload, message, DatePicker, Radio, Input, Tooltip } from 'antd';
import { UploadOutlined, InfoCircleFilled } from '@ant-design/icons';
import classNames from 'classnames';
import * as surveyActions from 'actions';
import Brief from './Summary';
import {
  checkUserHasSearchPermissions,
  hasWritePermissions
} from '../util/util';
import { DASH_DATE_FORMAT } from '../util/Constants';

const { Option } = Select;

const SurveyView = ({ t, history }) => {
  const dispatch = useDispatch();
  const suvc = useSelector(state => _.get(state.user, 'data.suvc', null));
  const supplierUserName = useSelector(state => _.get(state.user, 'data.name', null));
  const contactId = useSelector(state => _.get(state.user, 'data.contactId', null));
  const questions = useSelector(state => _.get(state.survey, 'data.questions', []));
  const issueDate = useSelector(state => _.get(state.survey, 'data.issueDate', ''));
  const certificationInfo = useSelector(state => _.get(state.survey, 'data.certificationInfo', {}));
  const surveyError = useSelector(state => _.get(state.survey, 'error', null));
  const surveySubmiting = useSelector(state => _.get(state.survey, 'submitingSurvey', false));
  const surveyFetchingForInternal = useSelector(state => _.get(state.survey, 'fetchingResultForInternal', false));
  const permissions = useSelector(state => _.get(state.user, 'data.permissions', []));
  const vendors = useSelector(state => _.get(state.survey, 'vendors', []));

  const [fileErrMsg, setFileErrMsg] = useState('');
  // const [dateErrMsg, setDateErrMsg] = useState('');
  const expirationDateRef = useRef('');
  const [dateVal, setDateVal] = useState('');
  const [fileList, setFileList] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const hasSearchPermissions = checkUserHasSearchPermissions(permissions);
  const hasWriteAccess = hasWritePermissions(permissions);

  const uploadProps = {
    name: 'certificateFile',
    onChange: info => {
      let newFileList = [...info.fileList];
      newFileList = newFileList.slice(-1);
      newFileList = newFileList.map(file => {
        file.status = 'done';
        return file;
      });
      if ((expirationDateRef.current || dateVal) && newFileList.length === 0) {
        setFileErrMsg('please select certification file');
      } else {
        setFileErrMsg('');
      }
      setFileList(newFileList);
    },
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      /*const allowedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];
      const isAllowedType = allowedTypes.includes(file.type);
      if (!isAllowedType) {
        message.error(`${file.name} is not a supported file type`);
        return false || Upload.LIST_IGNORE;
      }*/
      if (file.size >= (8 * 1024 * 1024)) {
        message.error('File too large. 8MB max.');
        return false || Upload.LIST_IGNORE;
      }
      setFileList([...fileList, file]);
      return false;
    },
    showUploadList: {
      showDownloadIcon: true,
      showRemoveIcon: true,
    },
    onDownload: file => {
      if (!file.originFileObj) {
        dispatch(surveyActions.downloadFile(certificationInfo.otmmId, certificationInfo.title));
      }
    },
    fileList
  };

  const hasNewChanges = () => {
    for (let i = 0; i < answers.length; i++) {
      const answer = answers[i];
      const question = questions.find((q) => q.id === answer.questionId);

      if (question.type === "option" && answer.optionId !== question.answer) {
        return true;
      }

      if (question.type === "text" && answer.textAnswer !== question.answer) {
        return true;
      }

      if (dateVal && !moment(dateVal).isSame(moment(issueDate), 'day')) {
        return true;
      }
    }

    if (fileList.length && fileList[0].originFileObj) {
      return true;
    }

    return false;
  };


  const isFileUploadRequired = () => {
    return _.some(answers, i => ["3", "4", "5", "6"].includes(i.optionId));
  }

  const isInputsValid = () => {
    let isValid = true;

    if (_.find(answers, { questionId: 1, optionId: '7' }) && !_.find(answers, { questionId: 2 })) {
      isValid = false;
    }

    if (isFileUploadRequired() && !_.find(answers, { questionId: 3 })) {
      isValid = false;
    }

    return isValid;
  }

  useEffect(() => {
    const hasChanges = hasNewChanges();
    const isValid = isInputsValid();
    let isFileUploadReady = true;

    if (!_.isEmpty(fileErrMsg)) {
      isFileUploadReady = false;
    }

    const isEnabled = hasChanges && isValid && isFileUploadReady && hasWriteAccess;
    setIsSubmitEnabled(isEnabled);
  }, [answers, questions, fileList, dateVal, hasWriteAccess]);

  useEffect(() => {
    let initialAnswers = []

    _.forEach(questions, que => {
      if (!_.isEmpty(que.answer)) {
        if (que.type === 'text') {
          initialAnswers.push({ questionId: que.id, optionId: null, textAnswer: que.answer });
        } else {
          initialAnswers.push({ questionId: que.id, optionId: que.answer, textAnswer: null });
        }
      }
    })

    setAnswers(filterVisibleAnswers(questions, initialAnswers));
  }, [questions]);

  useEffect(() => {
    setDateVal(issueDate ? issueDate.substr(0, 10) : '');
  }, [issueDate]);

  useEffect(() => {
    if (certificationInfo && certificationInfo.title) {
      setFileList([
        {
          documentId: certificationInfo.otmmId,
          name: certificationInfo.title,
          status: 'done',
          url: ''
        }
      ]);
    }
  }, [certificationInfo])

  const getStepParams = () => {
    let attributes = {}

    _.forEach(questions, que => {
      const ansObj = _.find(answers, { questionId: que.id });
      const ansId = que.type === "option" ? _.get(ansObj, 'optionId', '') : _.get(ansObj, 'textAnswer', '');
      const stepAns = _.find(que.options, { value: ansId });

      attributes[que.stepPropertyPath.split('.')[0]] = que.type === "text" ? ansId : _.get(stepAns, 'text', '');
    })

    const expiryDate = dateVal ? `${dateVal} 00:00:00` : '';
    attributes['certificate_issue_date'] = isFileUploadRequired() ? expiryDate : '';

    return {
      supplierUserName,
      attributes
    }
  }

  const onBtnClick = () => {
    const stepParams = getStepParams();
    const vendor = JSON.parse(localStorage.getItem('vendor'));
    let subSuvcs = [];
    if (vendor && vendor.subSuvcs.length) {
      subSuvcs = vendor.subSuvcs.map(item => item.suvc).filter(iter => (iter + '') !== (suvc + ''));
    }

    let folderId = '';
    if (vendor && vendor.folderId) {
      folderId = vendor.folderId
    } else {
      const localFolderId = localStorage.getItem(`${suvc}_folderId`);
      if (localFolderId) folderId = localFolderId;
    }

    const mustHaveFile = isFileUploadRequired();
    let oldDocumentId = '', oldFileName = '';

    if (mustHaveFile && fileList.length && !fileList[0].originFileObj) {
      oldDocumentId = fileList[0].documentId;
      oldFileName = fileList[0].name;
    }

    const submitParams = {
      suvc,
      contactId,
      answerList: answers,
      fileList,
      stepParams,
      subSuvcs,
      folderId,
      expiryDate: dateVal ? dateVal : '',
      oldDocumentId,
      oldFileName,
      history
    };
    dispatch(surveyActions.submitSurvey(submitParams));
  };

  const onSelect = value => {
    dispatch(surveyActions.fetchResultForInternal(value));
  };

  const disablePastDt = current => {
    return current.isBefore(moment().subtract(1, 'day'));
  };

  const onDateChange = value => {
    setDateVal(value ? value.format(DASH_DATE_FORMAT) : '');
    if (value && fileList.length === 0) {
      setFileErrMsg('please select certification file');
      expirationDateRef.current = value.format(DASH_DATE_FORMAT);
    } else {
      setFileErrMsg('');
      expirationDateRef.current = value ? value.format(DASH_DATE_FORMAT) : '';
    }
  };

  const handleChange = (questionId, value, type) => {
    setAnswers(prevAnswers => {
      let updatedAnswers = prevAnswers.filter(answer => answer.questionId !== questionId);

      if (type === 'radio') {
        updatedAnswers.push({ questionId, optionId: value, textAnswer: null });
      } else if (type === 'text' && `${value}`.trim()) {
        updatedAnswers.push({ questionId, optionId: null, textAnswer: value });
      }

      // Filter the answers to only include those for visible questions
      return filterVisibleAnswers(questions, updatedAnswers);
    });
  };

  const renderTooltip = (qId) => {
    let content = null;
    const style = { paddingLeft: '15px', paddingRight: '15px' }

    if (qId === 2) {
      content = (
        <div style={style}>
          If you do not know your company's science-based targets status, you can search by company or global parent company name in the search function on the SBTi website here:&nbsp;
          <a
            className="hyper-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://sciencebasedtargets.org/companies-taking-action#dashboard"
            style={{ color: '#008ace' }}
          >
            Companies taking action - Science Based Targets
          </a>
        </div>
      )
    } else if (qId === 3) {
      content = (
        <div style={style}>
          Company/organization names recorded in SBTi may differ from that used for Sysco or may refer to a parent/other related company.<br /><br />
          Please visit Companies taking action - Science Based Targets and search for your company name registered.<br /><br />
          Taking action - Science Based Targets link:&nbsp;
          <a
            className="hyper-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://sciencebasedtargets.org/companies-taking-action#dashboard"
            style={{ color: '#008ace' }}
          >
            Companies taking action - Science Based Targets
          </a>
        </div>
      )
    }

    if (content) {
      return (
        <Tooltip
          title={content}
        >
          <InfoCircleFilled style={{ color: '#008ace', marginLeft: '5px', cursor: 'pointer', opacity: 0.6 }} />
        </Tooltip>
      )
    }
  }

  const renderFileUpload = (qId, currentAnswer) => {
    const isVisible = qId === 2 && _.includes(["3", "4", "5", "6"], currentAnswer?.optionId);
    const disabled = hasSearchPermissions || !hasWriteAccess;

    if (isVisible) {
      return (
        <div className="detail-wrapper cert-wrapper" >
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span className="form-title">Certification</span>
            <Upload {...uploadProps} disabled={disabled}>
              <Button icon={<UploadOutlined />}>Click to Select</Button>
            </Upload>
            {fileErrMsg && <span className="validation-error" style={{ marginLeft: '96px' }}>{fileErrMsg}</span>}
          </div>
          <div>
            <span className="form-title">Issue Date of Certification</span>
            <DatePicker
              value={dateVal ? moment(dateVal, DASH_DATE_FORMAT) : null}
              disabledDate={disablePastDt}
              placeholder="Select Date"
              format={DASH_DATE_FORMAT}
              onChange={onDateChange}
              disabled={disabled}
            />
            {/* {dateErrMsg && <span className="validation-error" style={{ marginLeft: '208px' }}>{dateErrMsg}</span>} */}
          </div>
        </div>
      )
    }
  }

  const renderQuestions = () => {
    const radioStyle = {
      display: 'block',
      marginLeft: '10px'
    };

    let qNum = 0;

    return (
      <React.Fragment>
        {questions.map((question) => {
          const showCriteria = question.showCriteria ? JSON.parse(question.showCriteria) : null;
          const shouldShow = evaluateShowCriteria(showCriteria, answers, questions);

          if (!shouldShow) return null;

          const currentAnswer = _.find(answers, { questionId: question.id });
          const disabled = hasSearchPermissions || !hasWriteAccess;

          qNum += 1;

          return (
            <React.Fragment key={question.id}>
              {question.type === 'option' ? (
                <div className="detail-wrapper" style={{ display: 'block' }}>
                  <div className="question-content">
                    <p>{`${qNum}. ${question.question}`}</p>
                    {renderTooltip(question.id)}
                  </div>
                  <Radio.Group
                    onChange={(e) => handleChange(question.id, e.target.value, 'radio')}
                    value={currentAnswer?.optionId}
                    disabled={disabled}
                  >
                    {question.options.map((option) => (
                      <Radio key={option.value} value={option.value} style={radioStyle}>
                        {option.text}
                      </Radio>
                    ))}
                  </Radio.Group>
                </div>
              ) : (
                <div className="detail-wrapper" style={{ display: 'block' }}>
                  <div className="question-content">
                    <p>{`${qNum}. ${question.question}`}</p>
                    {renderTooltip(question.id)}
                  </div>
                  <Input
                    className="input-box"
                    disabled={disabled}
                    onChange={(e) => handleChange(question.id, e.target.value, 'text')}
                    value={currentAnswer?.textAnswer || ''}
                  />
                </div>
              )}
              {renderFileUpload(question.id, currentAnswer)}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    )
  }

  return (
    <div className="main-concent-wrapper">
      <Brief t={t} />
      {hasSearchPermissions && (
        <div>
          <Select
            className="supplier-select"
            placeholder="Select Supplier"
            onSelect={onSelect}
            showSearch
            optionFilterProp="children"
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            <Option key={0} value={null} disabled>
              Select Supplier
            </Option>
            {vendors.map(item => (
              <Option key={item.suvc} value={item.suvc} title={`${item.suvc} - ${item.supplierName}`}>
                {item.suvc} - {item.supplierName}
              </Option>
            ))}
          </Select>
          <span className="info-message">Please select desired supplier to display survey result</span>
        </div>
      )}

      <Spin tip={surveySubmiting ? "Submiting..." : "Loading..."} spinning={(hasSearchPermissions && surveyFetchingForInternal) || surveySubmiting}>

        {surveyError ? null : (
          <React.Fragment>
            {renderQuestions()}
            {!hasSearchPermissions && (
              <Button
                disabled={!isSubmitEnabled}
                className={classNames('footer-btn btn-confirm', { 'disable-btn': !isSubmitEnabled })}
                onClick={onBtnClick}
              >
                Submit
              </Button>
            )}
          </React.Fragment>
        )}
      </Spin>
    </div>
  );
};

const getVisibleQuestions = (questions, answers) => {
  const visibleQuestions = questions.filter(question =>
    evaluateShowCriteria(
      question.showCriteria ? JSON.parse(question.showCriteria) : null,
      answers,
      questions
    )
  );

  return visibleQuestions;
}

const filterVisibleAnswers = (questions, answers) => {
  const visibleQuestions = getVisibleQuestions(questions, answers);
  const visibleQuestionIds = visibleQuestions.map(q => q.id);

  // Only include answers for visible questions
  return answers.filter(answer => visibleQuestionIds.includes(answer.questionId));
};

const evaluateShowCriteria = (criteria, answers, questions) => {
  if (!criteria) return true;

  const { logic, conditions } = criteria;

  const evaluateCondition = (condition) => {
    if (condition.conditions) {
      return evaluateShowCriteria(condition, answers, questions);
    }
    const questionId = parseInt(condition.questionId);
    const optionId = condition.optionId;

    return (
      _.find(answers, { questionId, optionId })
    );
  };

  if (logic === 'OR') {
    return _.some(conditions, evaluateCondition);
  }

  if (logic === 'AND') {
    return _.every(conditions, evaluateCondition);
  }

  return false;
};


export default withTranslation()(SurveyView);
