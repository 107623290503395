import { takeLatest } from 'redux-saga/effects';
import { FETCHSURVEYRESULT, SUBMITSURVEY, FETCHSUPPLIERS, FETCHRESULTFORINTERNAL, DOWNLOADFILE } from 'actions/actionTypes';
import {
  fetchSurveyResultAsync,
  submitSurveyAsync,
  fetchSuppliersAsync,
  fetchResultForInternalAsync,
  downloadFileAsync
} from './SurveySaga';

const surveySaga = [
  takeLatest(FETCHSURVEYRESULT.REQUEST, fetchSurveyResultAsync),
  takeLatest(SUBMITSURVEY.REQUEST, submitSurveyAsync),
  takeLatest(FETCHSUPPLIERS.REQUEST, fetchSuppliersAsync),
  takeLatest(FETCHRESULTFORINTERNAL.REQUEST, fetchResultForInternalAsync),
  takeLatest(DOWNLOADFILE.REQUEST, downloadFileAsync)
];

export default surveySaga;
