import { SSSTOSTEP } from "./Constants";

const checkUserHasEditPermissions = permissions => permissions.includes('SUSTAINABILITYSOWAPP.EDIT.ALL');
const checkUserHasSearchPermissions = permissions => permissions.includes('SUSTAINABILITYSOWAPP.SEARCH.SUPPLIERS');
const checkStatusWithTwoAndConditions = (condition, secondCondition) => condition && secondCondition;
const getFieldDisabledStatusByTwoParams = (condition, secondCondition) => condition || secondCondition;

const getUniqueId = () => {
  const uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
  return uniqueId;
};

const getOtmmBaseUrl = () => {
  let url = process.env.REACT_APP_BFF.replace(/\/commitment$/, '').replace(/9146/, '8093');
  if (process.env.REACT_APP_BFF.match(/localhost/i)) {
    url = 'https://bff-qa.sss.mdm-supplier-np.us-east-1.aws.sysco.net';
  }
  url = 'http://localhost:8093'; //Melody Todo
  return url;
};

const getFileNameWithoutExtension = (filename) => {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return filename;
  }
  const filenameWithoutExtension = filename.substring(0, lastDotIndex);
  return filenameWithoutExtension;
}

const hasWritePermissions = (permissions) => permissions.includes('SUSTAINABILITYSOWAPP.ANSWERS.EDIT');

export {
  checkUserHasEditPermissions,
  checkUserHasSearchPermissions,
  getUniqueId,
  getOtmmBaseUrl,
  checkStatusWithTwoAndConditions,
  getFieldDisabledStatusByTwoParams,
  getFileNameWithoutExtension,
  hasWritePermissions
};
