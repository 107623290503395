import { createRequestTypes } from 'reduxHelpers';

const USER = createRequestTypes('USER');
const FETCHSURVEYRESULT = createRequestTypes('FETCHSURVEYRESULT');
const SUBMITSURVEY = createRequestTypes('SUBMITSURVEY');
const FETCHSUPPLIERS = createRequestTypes('FETCHSUPPLIERS');
const FETCHRESULTFORINTERNAL = createRequestTypes('FETCHRESULTFORINTERNAL');
const DOWNLOADFILE = createRequestTypes('DOWNLOADFILE');

export { USER, FETCHSURVEYRESULT, SUBMITSURVEY, FETCHSUPPLIERS, FETCHRESULTFORINTERNAL, DOWNLOADFILE };
