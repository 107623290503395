import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as userActions from 'actions';
import _ from 'lodash';
import AppLoader from 'components/Templates/AppLoader';
import Home from './pages';
import HeaderTab from './components/Templates/HeaderTab';

function MainApp({ userId }) {
  const dispatch = useDispatch();
  const userFetching = useSelector(state => _.get(state.user, 'fetching', false));
  useEffect(() => {
    dispatch(userActions.loadUserDetails(userId));
  }, [dispatch, userId]);

  if (!userFetching) {
    return (
      <div className="wrapper commitment-wrapper">
        <HeaderTab />
        <BrowserRouter>
          <Switch>
            <Route exact path="/suite/supplier-climate-commitment" component={Home} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  } else {
    return <AppLoader show />;
  }
}

export default MainApp;
